import { PlusOutlined } from "@ant-design/icons";
import { ImageField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Button, Image, Space, Upload } from "antd";
import { TOKEN_KEY, baseURL } from "authProvider";
import { get } from "lodash";
import styled from "styled-components";

export default function UploadFile({ value, onChange }: any) {
  const t = useTranslate();
  return (
    <Styled>
      <Upload.Dragger
        name="files[]"
        action={`${baseURL}/upload.admin`}
        listType="picture"
        accept="image/png, image/jpeg"
        showUploadList={false}
        headers={{
          Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
        }}
        style={{ padding: 0 }}
        onChange={(e) => {
          console.log(e);
          const v = get(e, "file.response[0].name");
          if (v) {
            onChange && onChange(v);
          }
        }}
      >
        {value ? (
          <Image
            src={`${baseURL}/view-file/${value}?token=${localStorage.getItem(
              TOKEN_KEY
            )}`}
            preview={false}
          />
        ) : (
          <Space direction="vertical">
            <PlusOutlined />
            <p
              style={{ minWidth: 60, fontSize: 12 }}
              className="ant-upload-text"
            >
              {t("common.upload")}
            </p>
          </Space>
        )}
      </Upload.Dragger>
    </Styled>
  );
}

const Styled = styled.div`
  height: 150px;
  width: 150px;
  .ant-upload {
    padding: 4px !important;
    img {
      object-fit: cover;
    }
  }
`;
