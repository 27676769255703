import {
  EnvironmentFilled,
  EnvironmentOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import ReactMapGL, { Marker } from "@goongmaps/goong-map-react";
import { Popover } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import ViewImage from "./view_image";

interface MapInputProp {
  value?: string;
  onChange?: Function;
}

const MapInput = ({ value, onChange }: MapInputProp) => {
  const lat = parseFloat(value?.split("||")[0] || "0");
  const lng = parseFloat(value?.split("||")[1] || "0");

  const [viewport, setViewport] = useState({
    latitude: lat,
    longitude: lng,
    zoom: 12,
  });

  useEffect(() => {
    setViewport({ zoom: 12, latitude: lat, longitude: lng });
  }, [lat]);

  return (
    <div style={{ height: "500px", width: "100%", position: "relative" }}>
      <ReactMapGL
        {...viewport}
        goongApiAccessToken="qGID8GKuOB5WRG7I0ELn4Lzxu0JZixl78k9RGCjJ"
        width="100%"
        height="100%"
        onViewportChange={(viewport: any) => setViewport(viewport)}
        onClick={(e) => {
          onChange && onChange(e.lngLat.reverse().join("||"));
        }}
      >
        <Marker latitude={lat} longitude={lng} offsetLeft={-20} offsetTop={-10}>
          <EnvironmentFilled style={{ fontSize: 30, color: "#096DD9" }} />
        </Marker>
      </ReactMapGL>
      <Zoom>
        <span>
          <PlusCircleOutlined
            onClick={() =>
              setViewport({ ...viewport, zoom: viewport.zoom + 1 })
            }
          />
        </span>

        <span>
          <MinusCircleOutlined
            onClick={() =>
              setViewport({ ...viewport, zoom: viewport.zoom - 1 })
            }
          />
        </span>
      </Zoom>
    </div>
  );
};

export default MapInput;

export const MapView = ({center, locations }: any) => {
  const [viewport, setViewport] = useState({
    latitude: center?.lat || 0,
    longitude: center?.long || 0,
    zoom: 12,
  });

  if (locations.length) {
    return (
      <div style={{ height: "500px", width: "100%", position: "relative" }}>
        <ReactMapGL
          {...viewport}
          width="100%"
          onViewportChange={(viewport: any) => setViewport(viewport)}
          height="100%"
          goongApiAccessToken="qGID8GKuOB5WRG7I0ELn4Lzxu0JZixl78k9RGCjJ"
        >
          {locations.map((a: any) => (
            <Marker
              key={a.id}
              latitude={a.lat || 0}
              longitude={a.long || 0}
              offsetLeft={-20}
              offsetTop={-10}
            >
              <Popover
                content={
                  <div>
                    {a.logo && (
                      <div style={{ margin: -12, marginBottom: 5 }}>
                        <ViewImage
                          value={a.logo}
                          width={220}
                          height={110}
                          preview={false}
                        />
                      </div>
                    )}
                    <p style={{ margin: 0 }}>
                      <strong>{a.name}</strong>
                    </p>
                    <p style={{ opacity: 0.45, margin: 0 }}>{a.description}</p>
                  </div>
                }
              >
                <EnvironmentFilled style={{ fontSize: 30, color: "#096DD9" }} />
              </Popover>
            </Marker>
          ))}
        </ReactMapGL>

        <Zoom>
          <span>
            <PlusCircleOutlined
              onClick={() =>
                setViewport({ ...viewport, zoom: viewport.zoom + 1 })
              }
            />
          </span>

          <span>
            <MinusCircleOutlined
              onClick={() =>
                setViewport({ ...viewport, zoom: viewport.zoom - 1 })
              }
            />
          </span>
        </Zoom>
      </div>
    );
  }
  return <div />;
};

const Zoom = styled.div`
  position: absolute;
  right: 10px;
  border-radius: 2px;
  bottom: 40px;
  font-size: 16px;

  display: flex;
  flex-direction: column;
  span {
    cursor: pointer;
    padding: 2px 4px;
    background: white;
  }
`;
