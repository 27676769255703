import React, { useState } from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useUpdate,
  useGetIdentity,
  useCreate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  useSelect,
  DateField,
  ShowButton,
  useForm,
  DeleteButton,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Button,
  Popconfirm,
  Switch,
  Card,
  Row,
  Col,
  Input,
  Select,
  Tag,
  Typography,
  Form,
} from "antd";
import { IUser } from "types";
import { Link } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";

export const TemplateList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { tableProps, setFilters, tableQueryResult, filters } = useTable({
    syncWithLocation: true,
  });

  const [_f, _setF] = useState<any>();

  const roleName = localStorage.getItem("ROLE_NAME");

  const { data: user } = useGetIdentity<IUser>();
  const total = tableQueryResult.data?.total || 0;
  const maxAllow = user?.package?.maxTemplate || 0;

  const { mutate } = useCreate()

  return (
    <List
      createButtonProps={{
        hidden: roleName != "company_manager",
        children: t("template.create_new"),
      }}
      title={
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t("template.title")}
          </Typography.Title>
          {roleName == "company_admin" && (
            <Typography.Text>
              {maxAllow ? `${total}/${maxAllow}` : ""}
            </Typography.Text>
          )}
        </div>
      }
    >
      <FilterBlock filters={filters} setFilters={setFilters} />
      <br />

      <Card
        title={
          <Space>
            <Typography.Text>{t("template.label_search")}:</Typography.Text>
            <Input.Search
              allowClear
              placeholder={t("template.search")}
              onSearch={(e) => {
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value: e || undefined,
                  },
                ]);
              }}
            />
          </Space>
        }
      >
        <Table
          {...tableProps}
          rowKey="id"
          scroll={{ x: 1200 }}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} ${t('common.of')} ${total}`,
          }}
        >
          <Table.Column
            dataIndex="name"
            fixed="left"
            title={t("template.template")}
            render={(v, record: any) => (
              <Link to={"/template/show/" + record.id}>{v}</Link>
            )}
          />

          <Table.Column
            dataIndex="status"
            width={120}
            title={t("template.status")}
            render={(v) =>
              v == "ACTIVE" ? (
                <Tag color="blue">{t("template.status_active")}</Tag>
              ) : (
                <Tag color="default">{t("template.status_inactive")}</Tag>
              )
            }
          />

          <Table.Column
            dataIndex={["createdTime"]}
            title={t("common.created_at")}
            width={120}
            sorter
            render={(value: any) => <DateField value={value} />}
          />

          <Table.Column
            dataIndex={["updatedTime"]}
            title={t("common.updated_at")}
            render={(value: any) => <DateField value={value} />}
            width={140}
            sorter
          />
          <Table.Column
            dataIndex={["activeTime"]}
            sorter
            width={120}
            title={t("template.active_date")}
            render={(value: any, r: any) =>
              r.status == "ACTIVE" && value ? <DateField value={value} /> : "-"
            }
          />
          {roleName == "company_admin" && (
            <Table.Column
              dataIndex={["user", "fullName"]}
              width={150}
              title={t("template.created_by")}
            />
          )}

          <Table.Column
            dataIndex={["totalQuestion"]}
            title={t("template.number_question")}
            align="right"
            width={100}
            render={(v) => v || "-"}
          />
          <Table.Column
            dataIndex={["totalUsers"]}
            title={t("template.number_use")}
            render={(v) => v || "-"}
            width={100}
            align="right"
          />
          <Table.Column
            dataIndex={["totalGroups"]}
            title={t("template.number_group")}
            render={(v) => v || "-"}
            width={100}
            align="right"
          />

          {roleName == "company_manager" && (
            <Table.Column
              width={120}
              title={t("common.action")}
              dataIndex="actions"
              fixed="right"
              align="center"
              render={(_, record: BaseRecord) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    hidden={record.status == "ACTIVE"}
                  />

                  <Popconfirm
                    title={t("template.confirm_clone")}
                    description={t("template.confirm_clone_desc")}
                    onConfirm={() => {
                      mutate({
                        resource: 'template',
                        meta: {
                          type: 'clone'
                        },
                        values: { id: record.id }
                      })
                    }}
                    okText={t("common.confirm_ok")}
                    cancelText={t("common.confirm_close")}
                  >
                    <Button
                      icon={<CopyOutlined />}
                      size="small"

                    />
                  </Popconfirm>

                  {!record.totalUsers && !record.totalGroups ? (
                    <DeleteButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                      hidden={record.status == "ACTIVE"}
                      title={t("template.desc_delete")}
                      confirmTitle={t("template.desc_delete")}
                      confirmOkText={t("template.delete")}
                      confirmCancelText={t("template.cancel")}
                    />
                  ) : null}
                </Space>
              )}
            />
          )}
        </Table>
      </Card>
    </List>
  );
};

const FilterBlock = ({ filters, setFilters }: any) => {
  const { formProps, form } = useForm({
    warnWhenUnsavedChanges: false,
  });

  const { selectProps } = useSelect({
    resource: "user",
    optionLabel: "fullName",
    optionValue: "userId",
  });
  const roleName = localStorage.getItem("ROLE_NAME");
  const t = useTranslate();
  return (
    <Card>
      <Form
        {...formProps}
        onValuesChange={(e) => {
          const d = form.getFieldsValue() as any;
          setFilters(
            [
              {
                field: "status",
                operator: "eq",
                value: d.status != "ALL" ? d.status : undefined,
              },
              {
                field: "user.userId",
                operator: "eq",
                value: d.userId,
              },
            ],
            "replace"
          );
        }}
      >
        <Row gutter={[12, 12]}>
          {roleName == "company_admin" && (
            <Col span={8}>
              <Form.Item
                style={{ margin: 0 }}
                name="userId"
                label={t("template.created_by")}
              >
                <Select
                  {...selectProps}
                  style={{ width: "100%" }}
                  placeholder={t("template.select_created_by")}
                  allowClear
                />
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item
              style={{ margin: 0 }}
              name="status"
              label={t("filter.status")}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={t("filter.status_placeholder")}
                options={[
                  { value: "ALL", label: t("common.all") },
                  { value: "ACTIVE", label: t("common.actived") },
                  { value: "INACTIVE", label: t("common.inactived") },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
