import React from "react";
import {
  IResourceComponentsProps,
} from "@refinedev/core";
import {
  Card,
} from "antd";

import { useSearchParams } from "react-router-dom";
import ViewImage from "components/view_image";

export const ViewFile: React.FC<IResourceComponentsProps> = () => {
  const [searchParams] = useSearchParams();
  return (
    <Card>
      <ViewImage preview={false} value={searchParams.get("name") || ""} />
    </Card>
  );
};
