import React, { useEffect } from "react";
import {
  IResourceComponentsProps,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import {
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  TimePicker,
  InputNumber,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import SelectCustom from "components/select";
import _ from "lodash";
import { MapView } from "components/map";
dayjs.extend(utc);

export const AssignCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, onFinish, form } = useForm({
    onMutationError(res) {
      const errors = _.get(res, "response.data.messages") || [];
      if (errors.length) {
        const fields = errors.map((e: any) => ({
          name: e.key,
          errors: [e.msg],
        }));
        form.setFields(fields);
        form.scrollToField(fields[0].name, {
          behavior: "smooth",
          block: "center",
        });
      }
    },
  });

  const { selectProps: templateSelectProps } = useSelect({
    resource: "template",
    optionLabel: "name",
    pagination: {
      pageSize: 10000,
    },
    filters: [
      {
        value: "ACTIVE",
        operator: "eq",
        field: "status",
      },
    ],
  });
  const { selectProps: userSelectProps } = useSelect({
    resource: "user",
    optionLabel: "fullName",
    optionValue: "userId",
    pagination: {
      pageSize: 10000,
    },
    filters: [
      {
        value: "ACTIVE",
        operator: "eq",
        field: "status",
      },
    ],
  });
  const { selectProps: groupSelectProps } = useSelect({
    resource: "group_user",
    optionLabel: "name",
    optionValue: "id",
    pagination: {
      pageSize: 10000,
    },
  });

  const checkListType = Form.useWatch("checkListType", formProps.form);

  const { data: locationData } = useList({
    resource: "location",
    // optionLabel: "name",
    filters: [
      {
        field: "status",
        value: "ACTIVE",
        operator: "eq",
      },
    ],
    // optionValue: "id",
    pagination: {
      pageSize: 10000,
    },
  });
  const locationList = locationData?.data || [];

  const userIds = Form.useWatch("userIds", formProps.form);
  const groupIds = Form.useWatch("groupIds", formProps.form);
  const qrCode = Form.useWatch("qrCode", formProps.form);
  const locationId = Form.useWatch("locationId", formProps.form);

  return (
    <Create
      saveButtonProps={{
        ...saveButtonProps,
        children: t("assign.save_create"),
      }}
      breadcrumb={null}
      title={t("assign.title_create")}
      footerButtonProps={{
        style: { float: "left", paddingLeft: "calc(25% + 12px)" },
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps?.initialValues,
          date: [
            formProps?.initialValues?.startTime
              ? dayjs(formProps?.initialValues?.startTime.slice(0, 10))
              : dayjs().startOf("M"),
            formProps?.initialValues?.endTime
              ? dayjs(formProps?.initialValues?.endTime.slice(0, 10))
              : dayjs().endOf("M"),
          ],
          isSelectImage: true,
          time: [
            dayjs(
              formProps?.initialValues?.timeHourStart || "06:00:00",
              "HH:mm:ss"
            ),
            dayjs(
              formProps?.initialValues?.timeHourEnd || "18:00:00",
              "HH:mm:ss"
            ),
          ],
          groupIds: (formProps?.initialValues?.groupIds || []).map(
            (a: any) => a * 1
          ),
          locationId: formProps?.initialValues?.location?.id,
          dayOfWeek: formProps?.initialValues?.dayOfWeek || [
            "1",
            "2",
            "3",
            "4",
            "5",
          ],
          assignType: formProps?.initialValues?.assignType || "DAILY",
          checkListType: formProps?.initialValues?.checkListType || "DAILY",
          status: formProps?.initialValues?.status || "ACTIVE",
        }}
        onFinish={(e: any) => {
          if (e.time) {
            e.timeHourStart = dayjs(e.time[0]).format("HH:mm:ss");
            e.timeHourEnd = dayjs(e.time[1]).format("HH:mm:ss");
          } else {
            e.timeHourStart = "00:00:00";
            e.timeHourEnd = "23:59:59";
          }

          e.startTime = dayjs(e.date[0]).format("YYYY-MM-DD 00:00:00");
          e.endTime = dayjs(e.date[1]).format("YYYY-MM-DD 00:00:00");

          onFinish(e);
        }}
      >
        <Form.Item name={["id"]} hidden>
          <Input disabled />
        </Form.Item>
        <Row gutter={12}>
          <Col span={6} />
          <Col span={12}>
            <Row gutter={12}>
              <Col span={16}>
                <Form.Item
                  label={t("assign.name")}
                  name={["name"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <Input maxLength={200} placeholder={t("assign.name")} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t("assign.code")}
                  name={["assignCode"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                    {
                      validator(rule, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (!/^[A-Z0-9_]+$/.test(value)) {
                          return Promise.reject(t("assign.code_validate"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input maxLength={15} placeholder={t("assign.code")} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t("assign.template")}
                  name={["templateId"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <SelectCustom
                    {...templateSelectProps}
                    placeholder={t("assign.select_template")}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t("assign.user")}
                  name={["userIds"]}
                  rules={[
                    {
                      required: !groupIds?.length,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <SelectCustom
                    {...userSelectProps}
                    mode="multiple"
                    placeholder={t("assign.select_user")}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t("assign.group")}
                  name={["groupIds"]}
                  rules={[
                    {
                      required: !userIds?.length,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <SelectCustom
                    {...groupSelectProps}
                    mode="multiple"
                    placeholder={t("assign.select_group")}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name={["checkListType"]}
                  label={t("assign.type")}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <Select>
                    <Select.Option value="DAILY">
                      {t("common.assign_type_DAILY")}
                    </Select.Option>
                    <Select.Option value="INCURRED">
                      {t("common.assign_type_INCURRED")}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {checkListType == "INCURRED" && (
                <Col span={12}>
                  <Form.Item
                    name={["maxWork"]}
                    label={t("assign.kpi")}
                    rules={[
                      {
                        required: true,
                        message: t("common.required"),
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              )}
              {checkListType == "DAILY" && (
                <Col span={12}>
                  <Form.Item
                    name={["dayOfWeek"]}
                    label={t("assign.repeat")}
                    rules={[
                      {
                        required: true,
                        message: t("common.required"),
                      },
                    ]}
                  >
                    <Select mode="multiple">
                      <Select.Option value="1">
                        {t("common.monday")}
                      </Select.Option>
                      <Select.Option value="2">
                        {t("common.tuesday")}
                      </Select.Option>
                      <Select.Option value="3">
                        {t("common.wednesday")}
                      </Select.Option>
                      <Select.Option value="4">
                        {t("common.thursday")}
                      </Select.Option>
                      <Select.Option value="5">
                        {t("common.friday")}
                      </Select.Option>
                      <Select.Option value="6">
                        {t("common.satuday")}
                      </Select.Option>
                      <Select.Option value="0">
                        {t("common.sunday")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Col span={checkListType == "DAILY" ? 12 : 24}>
                <Form.Item
                  label={t("assign.date_range")}
                  name={["date"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    style={{ width: "100%" }}
                    placeholder={[
                      t("common.select_start"),
                      t("common.select_end"),
                    ]}
                  />
                </Form.Item>
              </Col>

              {checkListType == "DAILY" && (
                <Col span={12}>
                  <Form.Item
                    label={t("assign.time_range")}
                    name={["time"]}
                    rules={[
                      {
                        required: true,
                        message: t("common.required"),
                      },
                    ]}
                  >
                    <TimePicker.RangePicker
                      placeholder={[
                        t("common.select_time_start"),
                        t("common.select_time_end"),
                      ]}
                      style={{ width: "100%" }}
                      format="HH:mm:ss"
                    />
                  </Form.Item>
                </Col>
              )}

              <Col span={24}>
                <Form.Item
                  label={t("assign.random")}
                  name={["randomQuestions"]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item valuePropName="checked" name={["isSelectImage"]}>
                  <Checkbox title="Requried qrcode">
                    {t("assign.select_photos")}
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item valuePropName="checked" name={["qrCode"]}>
                  <Checkbox title="Requried qrcode">
                    {t("assign.required_location")}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item valuePropName="checked" name={["isSeeInstruction"]}>
                  <Checkbox title="allow_view_guide">
                    {t("assign.allow_view_guide")}
                  </Checkbox>
                </Form.Item>
              </Col>

              {qrCode && (
                <>
                  <Col span={24}>
                    <Form.Item
                      name={["locationId"]}
                      label={t("assign.location")}
                      rules={[
                        {
                          required: !!qrCode,
                        },
                      ]}
                    >
                      <SelectCustom
                        placeholder={t("assign.select_location")}
                        options={locationList.map((a) => ({
                          value: a.id,
                          label: a.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  {locationId && (
                    <Col span={24}>
                      <MapView
                        center={locationList.find((a) => a.id == locationId)}
                        key={locationId}
                        locations={locationList.filter(
                          (a) => a.id == locationId
                        )}
                      />
                    </Col>
                  )}

                  <Col span={12}>
                    <Form.Item name={["status"]} hidden>
                      <Input maxLength={200} />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
