import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useGetIdentity,
  useUpdate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  useSelect,
  DateField,
  useForm,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Popconfirm,
  Switch,
  Card,
  Row,
  Col,
  Select,
  Typography,
  Input,
  Form,
  Avatar,
} from "antd";
import { IUser } from "types";
import ViewImage from "components/view_image";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { tableProps, setFilters, filters, tableQueryResult } = useTable({
    syncWithLocation: true,
  });

  const { mutate } = useUpdate();

  const { data: user } = useGetIdentity<IUser>();
  const total = tableQueryResult.data?.total || 0;

  const maxAllow = user ? user.package.maxUser + user.package.maxManager : 0;
  return (
    <List
      title={
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t("user.title")}
          </Typography.Title>
          <Typography.Text>
            {maxAllow ? `${total}/${maxAllow}` : ""}
          </Typography.Text>
        </div>
      }
      createButtonProps={{ children: t("user.create_new") }}
    >
      <FilterBlock setFilters={setFilters} filters={filters} />
      <br />
      <Card
        title={
          <Space>
            <Typography.Text>{t("user.label_search")}:</Typography.Text>
            <Input.Search
              allowClear
              placeholder={t("user.search")}
              onSearch={(e) => {
                setFilters([
                  {
                    field: "fullName",
                    operator: "contains",
                    value: e || undefined,
                  },
                ]);
              }}
            />
          </Space>
        }
      >
        <Table
          {...tableProps}
          rowKey="userId"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} ${t('common.of')} ${total}`,
          }}
        >
          <Table.Column
            dataIndex={["avatar"]}
            title={t("user.avatar")}
            width={100}
            render={(v, r: any) =>
              v ? (
                <ViewImage value={v} height={32} width={32} />
              ) : (
                <Avatar size={32} shape="square">
                  {r.fullName?.[0]}
                </Avatar>
              )
            }
          />
          <Table.Column dataIndex={["fullName"]} title={t("user.name")} />
          <Table.Column dataIndex={["email"]} title={t("user.email")} />
          <Table.Column
            dataIndex={["phone"]}
            width={160}
            title={t("user.phone")}
          />
          <Table.Column
            dataIndex={["createdTime"]}
            width={150}
            sorter
            title={t("common.created_at")}
            render={(value: any) => <DateField value={value} />}
          />

          <Table.Column
            dataIndex={["updatedTime"]}
            width={150}
            sorter
            title={t("common.updated_at")}
            render={(value: any) => <DateField value={value} />}
          />
          <Table.Column
            dataIndex={["permisionType"]}
            title={t("user.role")}
            render={(r) =>
              r == "STAFF" ? t("user.role_staff") : t("user.role_manager")
            }
          />

          <Table.Column
            title={t("common.action")}
            dataIndex="actions"
            align="center"
            width={120}
            render={(_, record: BaseRecord) => (
              <Space>
                <Popconfirm
                  title={t("common.confirm_change_state")}
                  description={t("common.desc_change_state")}
                  onConfirm={() => {
                    mutate({
                      id: record.id ?? "",
                      resource: "company_manager",
                      values: {
                        id: record.id ?? "",
                        status:
                          record.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
                      },
                    });
                  }}
                  okText={t("common.confirm_ok")}
                  cancelText={t("common.confirm_close")}
                >
                  <Switch checked={record.status == "ACTIVE"} />
                </Popconfirm>
                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </Card>
    </List>
  );
};

const FilterBlock = ({ filters, setFilters }: any) => {
  const { formProps, form } = useForm({
    warnWhenUnsavedChanges: false,
  });

  const { selectProps } = useSelect({
    resource: "group_user",
    optionLabel: "name",
  });
  const t = useTranslate();
  return (
    <Card>
      <Form
        {...formProps}
        onValuesChange={(e) => {
          const d = form.getFieldsValue() as any;
          setFilters(
            [
              {
                field: "status",
                operator: "eq",
                value: d.status != "ALL" ? d.status : undefined,
              },
              {
                field: "permisionType",
                operator: "eq",
                value: d.permisionType,
              },

              {
                field: "groups.id",
                operator: "in",
                value: d.groupIds?.length ? d.groupIds : undefined,
              },
            ],
            "replace"
          );
        }}
      >
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <Form.Item
              style={{ margin: 0 }}
              name="status"
              label={t("filter.status")}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={t("filter.status_placeholder")}
                options={[
                  { value: "ALL", label: t("common.all") },
                  { value: "ACTIVE", label: t("common.actived") },
                  { value: "INACTIVE", label: t("common.inactived") },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              style={{ margin: 0 }}
              name="permisionType"
              label={t("user.role_filter")}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                options={[
                  { value: "STAFF", label: t("user.role_staff") },
                  { value: "MANAGER", label: t("user.role_manager") },
                ]}
                placeholder={t("user.select_role")}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              style={{ margin: 0 }}
              name="groupIds"
              label={t("user.group")}
            >
              <Select
                allowClear
                {...selectProps}
                style={{ width: "100%" }}
                placeholder={t("user.select_group")}
                mode="multiple"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
