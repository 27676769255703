import { List, useForm, useModalForm, useSelect } from "@refinedev/antd";
import {
  useOne,
  useGetIdentity,
  useList,
  useTranslate,
  useGetLocale,
} from "@refinedev/core";
import {
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  BarController,
  BarElement,
  LinearScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {Link, useNavigate} from "react-router-dom";
import SelectCustom from "components/select";
import { MapView } from "components/map";
import { DownloadOutlined } from "@ant-design/icons";
import _ from "lodash";
import {TOKEN_KEY} from "../../authProvider";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement
);

export const Dashboard = () => {
  const { formProps, form } = useForm({
    warnWhenUnsavedChanges: false,
  });
  const { data: user } = useGetIdentity<any>();
  const userId = Form.useWatch("userId", form);
  const date = dayjs(Form.useWatch("date", form)).format("YYYY-MM-01");
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.userId && user.role?.name == "company_manager") {
      form.setFieldValue("userId", user?.userId);
    }
  }, [user?.userId, user?.role?.name]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem("MANAGER_ID", userId);
    }
  }, [userId]);

  const company = user?.company || {};

  const locale = useGetLocale();
  const currentLocale = locale();

  useEffect(() => {
    form.setFieldValue("date", dayjs());
  }, []);

  const { data } = useOne({
    resource: "dashboard",
    id: userId,

    meta: {
      type: "list",
      value: {
        managerId: userId,
        date: date,
      },
    },
  });
  const { data: dataUser } = useOne({
    resource: "dashboard",
    id: userId,
    meta: {
      type: "listByUser",
      value: {
        managerId: userId,
        date: date,
      },
    },
  });
  const { data: dataLocation } = useOne({
    resource: "dashboard",
    id: userId,
    meta: {
      type: "listByLocation",
      value: {
        managerId: userId,
        date: date,
      },
    },
  });

  const { data: dataCalendar } = useOne({
    resource: "dashboard",
    id: userId,
    meta: {
      type: "caculate",
      value: {
        managerId: userId,
        date: date,
      },
    },
  });

  const dataCalendarList = dataCalendar?.data?.data || [];

  const record = data?.data;
  const users = dataUser?.data || ([] as any);
  const locations = dataLocation?.data || ([] as any);

  const { data: dataLocationList } = useList({
    resource: "location",
    filters: [
      {
        field: "status",
        value: "ACTIVE",
        operator: "eq",
      },
    ],
  });
  const locationList = dataLocationList?.data || [];

  const { selectProps } = useSelect({
    resource: "user",
    filters: [
      {
        field: "permisionType",
        operator: "eq",
        value: "MANAGER",
      },
    ],
    optionLabel: "fullName",
    optionValue: "userId",
    pagination: {
      pageSize: 10000,
    },
  });
  const t = useTranslate();
  const dates = new Array(31)
    .fill("")
    .map((v, i) => dayjs(date).set("D", i).format("DD/MM"));
  const datasets = useMemo(
    () => [
      {
        label: t("common.MISS"),
        data: dates.map(
          (d) =>
            dataCalendarList.find(
              (a: any) => dayjs(a.date).format("DD/MM") == d
            )?.value?.MISS
        ),
        backgroundColor: "#FF4D4F",
      },
      {
        label: t("common.NG"),
        data: dates.map(
          (d) =>
            dataCalendarList.find(
              (a: any) => dayjs(a.date).format("DD/MM") == d
            )?.value?.NG
        ),

        backgroundColor: "#40A9FF",
      },
      {
        label: t("common.OK"),
        data: dates.map(
          (d) =>
            dataCalendarList.find(
              (a: any) => dayjs(a.date).format("DD/MM") == d
            )?.value?.OK
        ),
        backgroundColor: "#73D13D",
      },
    ],
    [dataCalendar]
  );

  const {
    show,
    modalProps,
    formProps: formPropsExport,
    form: formExport,
    onFinish,
  } = useModalForm({
    resource: "report_template",
    meta: {
      type: "export",
      responseType: "arraybuffer",
    },
    action: "create",
    onMutationSuccess: (response: any) => {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a link element and trigger a download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const nameTemplate = selectPropsTemplate.options?.find(
        (a) => a.value == formExport.getFieldValue("templateId")
      )?.label;
      link.download = `${nameTemplate}_${dayjs().format("YYYYMMDD-HH-mm")}_${currentLocale}`;
      // Append the link to the body and programmatically click it
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    },
  });
  const { selectProps: selectPropsTemplate } = useSelect({
    resource: "template",
    optionLabel: "name",
    filters: [
      {
        field: "status",
        operator: "eq",
        value: "ACTIVE",
      },
    ],
  });

  return (
    <List
      title={t("board.title")}
      headerButtons={
        <Button color="blue" onClick={() => show()} icon={<DownloadOutlined />}>
          {t("board.download")}
        </Button>
      }
    >
      <Modal
        {...modalProps}
        title={t("board.download_btn")}
        okText={t("board.download_btn")}
        width={400}
      >
        <Form
          layout="vertical"
          {...formPropsExport}
          onFinish={(d: any) => {
            const d2 = {
              ...d,
              language: currentLocale == 'vi' ? 'vi' : 'en',
              startDate: dayjs(d.startDate).format("YYYY-MM-DD"),
              endDate: dayjs(d.endDate).format("YYYY-MM-DD"),
            };
            onFinish(d2);
          }}
          initialValues={{}}
        >
          <Form.Item
            name="templateId"
            label={t("board.select_template")}
            rules={[{ required: true }]}
          >
            <Select
              {...selectPropsTemplate}
              allowClear
              placeholder={t("board.select_template")}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Row gutter={12}>
            <Col md={12}>
              <Form.Item
                name="startDate"
                label={t("board.select_start_date")}
                rules={[{ required: true }]}
              >
                <DatePicker
                  placeholder={t("board.select_start_date")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="endDate"
                label={t("board.select_end_date")}
                rules={[{ required: true }]}
              >
                <DatePicker
                  placeholder={t("board.select_end_date")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Card>
        <Row gutter={[12, 12]}>
          <Col md={24}>
            <Form {...formProps}>
              <Row gutter={[12, 12]}>
                <Col md={8} hidden={user?.role?.name != "company_admin"}>
                  <Form.Item
                    name="userId"
                    label={t("board.manager")}
                    style={{ marginBottom: 0 }}
                  >
                    <SelectCustom
                      allowClear
                      style={{ width: "100%" }}
                      {...selectProps}
                      label={t("board.select_manager")}
                    />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="date"
                    label={t("board.select_month_filter")}
                    style={{ marginBottom: 0 }}
                  >
                    <DatePicker.MonthPicker
                      style={{ width: "100%" }}
                      placeholder={t("board.select_month")}
                      allowClear={false}
                      format={"[" + t("board.month") + "] MM/YYYY"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
      <Row gutter={[12, 12]}>
        {userId && (
          <>
            <Col md={12} />
            <Col md={24}>
              <Row gutter={[12, 12]}>
                <Col md={4}>
                  <Card>
                    <Typography.Text style={{ textAlign: "right" }}>
                      {t("board.group")}
                    </Typography.Text>
                    <Typography.Title level={2} style={{ color: "#FFC53D" }}>
                      {record?.totalGroup || "-"}
                    </Typography.Title>
                    <Typography.Title level={5} style={{ color: "#FFC53D" }}>
                      {t("board.group_items")}
                    </Typography.Title>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <Typography.Text>{t("board.template")}</Typography.Text>
                    <Typography.Title level={2} style={{ color: "#36CFC9" }}>
                      {record?.totalTemplate || "-"}
                    </Typography.Title>
                    <Typography.Title level={5} style={{ color: "#36CFC9" }}>
                      {t("board.template_active")}
                    </Typography.Title>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <Typography.Text>{t("board.assign")}</Typography.Text>
                    <Typography.Title level={2} style={{ color: "#9254DE" }}>
                      {record?.totalAssign || "-"}
                    </Typography.Title>
                    <Typography.Title level={5} style={{ color: "#9254DE" }}>
                      {t("board.assign_active")}
                    </Typography.Title>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <Typography.Text>{t("board.work")}</Typography.Text>
                    <Typography.Title level={2} style={{ color: "#BAE637" }}>
                      {record?.totalDone || "-"}
                    </Typography.Title>
                    <Typography.Title level={5} style={{ color: "#BAE637" }}>
                      {t("board.work_done")}
                    </Typography.Title>
                  </Card>
                </Col>

                <Col md={3}>
                  <Card>
                    <Typography.Text>{t("board.work")}</Typography.Text>
                    <Typography.Title level={2} style={{ color: "#73D13D" }}>
                      {record?.totalOk || "-"}
                    </Typography.Title>
                    <Typography.Title level={5} style={{ color: "#73D13D" }}>
                      {t("board.work_OK")}
                    </Typography.Title>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <Typography.Text>{t("board.work")}</Typography.Text>
                    <Typography.Title level={2} style={{ color: "#40A9FF" }}>
                      {record?.totalNg || "-"}
                    </Typography.Title>
                    <Typography.Title level={5} style={{ color: "#40A9FF" }}>
                      {t("board.work_NG")}
                    </Typography.Title>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <Typography.Text>{t("board.work")}</Typography.Text>
                    <Typography.Title level={2} style={{ color: "#FF4D4F" }}>
                      {record?.totalMiss || "-"}
                    </Typography.Title>
                    <Typography.Title level={5} style={{ color: "#FF4D4F" }}>
                      {t("board.work_MISS")}
                    </Typography.Title>
                  </Card>
                </Col>
                <Col md={24}>
                  <Card title={t("board.chart_date")}>
                    <Bar
                      height={100}
                      options={{
                        scales: {
                          x: {
                            stacked: true,
                          },
                          y: {
                            stacked: true,
                          },
                        },
                        plugins: {
                          legend: {
                            position: "top",
                          },
                          title: {
                            display: true,
                          },
                        },
                      }}
                      data={{
                        labels: dates,
                        datasets: datasets,
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <Card
                title={t("board.table_work")}
                extra={<Link to="/activity_user">{t("common.view_all")}</Link>}
              >
                <Table size="small" dataSource={users}>
                  <Table.Column
                    title={t("board.user")}
                    dataIndex="fullName"
                    render={(v, r: any) => (
                      <Link to={"/activity_user/show/" + r.userId}>{v}</Link>
                    )}
                  />
                  <Table.Column
                    title={t("board.done_date")}
                    dataIndex="doneByDay"
                  />
                  <Table.Column
                    title={t("board.done_week")}
                    dataIndex="doneByWeek"
                  />
                  <Table.Column
                    title={t("board.done_month")}
                    dataIndex="doneByMonth"
                  />
                  <Table.Column
                    title={t("board.done_avg")}
                    dataIndex="scoreAvg"
                  />
                  <Table.Column
                    title={t("board.total_ok")}
                    dataIndex="totalOk"
                  />
                  <Table.Column
                    title={t("board.total_ng")}
                    dataIndex="totalNg"
                  />
                  <Table.Column
                    title={t("board.total_miss")}
                    dataIndex="totalMiss"
                  />
                </Table>
              </Card>
            </Col>
            <Col md={12}>
              <Card
                title={t("board.calendar")}
                extra={dayjs().format("MM/YYYY")}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 16,
                    padding: 12,
                  }}
                >
                  <Space size="small">
                    <div
                      style={{
                        backgroundColor: "#FF4D4F",
                        height: 14,
                        width: 14,
                        borderRadius: 3,
                      }}
                    />
                    {t("common.MISS")}
                  </Space>

                  <Space size="small">
                    <div
                      style={{
                        backgroundColor: "#40A9FF",
                        height: 14,
                        width: 14,
                        borderRadius: 3,
                      }}
                    />

                    {t("common.NG")}
                  </Space>

                  <Space size="small">
                    <div
                      style={{
                        backgroundColor: "#73D13D",
                        height: 14,
                        borderRadius: 3,
                        width: 14,
                      }}
                    />
                    {t("common.OK")}
                  </Space>
                </div>
                <Calendar
                  value={dayjs(date)}
                  mode="month"
                  fullscreen={true}
                  headerRender={() => ""}
                  cellRender={(d) => {
                    var items: any = dataCalendarList.find(
                      (a: any) =>
                        dayjs(a.date).format("DD/MM") == d.format("DD/MM")
                    );

                    var ok = items?.value?.OK;
                    var ng = items?.value?.NG;
                    var miss = items?.value?.MISS;

                    return (
                      <Space direction="vertical" size="small" wrap onClick={()=>{
                        console.log("Jump in")
                        navigate("/activity_user?startDate="+items.date, { replace: true });
                      }}>
                        {ok > 0 && <Badge color="green" text={ok} />}
                        {ng > 0 && <Badge color="blue" text={ng} />}
                        {miss > 0 && <Badge color="red" text={miss} />}
                      </Space>
                    );
                  }}
                />
              </Card>
            </Col>
            <Col md={12}>
              <Card
                title={t("board.table_location")}
                extra={
                  <Link to="/activity_location">{t("common.view_all")}</Link>
                }
              >
                <Table size="small" dataSource={locations}>
                  <Table.Column
                    title={t("board.location")}
                    dataIndex="name"
                    render={(v, r: any) => (
                      <Link to={"/activity_location/show/" + r.id}>{v}</Link>
                    )}
                  />
                  <Table.Column
                    title={t("board.done_date")}
                    dataIndex="doneByDay"
                  />
                  <Table.Column
                    title={t("board.done_week")}
                    dataIndex="doneByWeek"
                  />
                  <Table.Column
                    title={t("board.done_month")}
                    dataIndex="doneByMonth"
                  />
                  <Table.Column
                    title={t("board.total_ok")}
                    dataIndex="totalOk"
                  />
                  <Table.Column
                    title={t("board.total_ng")}
                    dataIndex="totalNg"
                  />
                  <Table.Column
                    title={t("board.total_miss")}
                    dataIndex="totalMiss"
                  />
                </Table>
              </Card>
            </Col>
            <Col md={12}>
              <Card title={t("board.location_map")}>
                <div style={{ height: "700px", width: "100%" }}>
                  <MapView
                    center={{
                      lat: _.toNumber(company?.lat),
                      long: _.toNumber(company?.long),
                    }}
                    locations={locationList}
                  />
                </div>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </List>
  );
};
