import React, { useEffect } from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  DateField,
  useForm,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Popconfirm,
  Switch,
  Card,
  Row,
  Form,
  Col,
  Select,
  DatePicker,
  Button,
  Input,
  Typography,
  Avatar,
} from "antd";
import dayjs from "dayjs";
import ViewImage from "components/view_image";
import { useNavigate } from "react-router-dom";
import { ReloadOutlined } from "@ant-design/icons";

export const CompanyList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { tableProps, setFilters, filters } = useTable({
    syncWithLocation: true,
  });

  const { mutate } = useUpdate();

  return (
    <List
      title={t("company.title")}
      createButtonProps={{ children: t("company.create_new") }}
    >
      <FilterBlock filters={filters} setFilters={setFilters} />
      <br />
      <Card
        title={
          <Space>
            <Typography.Text>{t("company.label_search")}:</Typography.Text>
            <Input.Search
              allowClear
              placeholder={t("company.search")}
              onSearch={(e) => {
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value: e || undefined,
                  },
                ]);
              }}
            />
          </Space>
        }
      >
        <Table
          {...tableProps}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} ${t('common.of')} ${total}`,
          }}
        >
          <Table.Column
            dataIndex="logo"
            title={t("company.logo")}
            render={(v, r: any) =>
              v ? (
                <ViewImage value={v} height={32} width={32} />
              ) : (
                <Avatar size={32} shape="square">
                  {r.companyCode[0]}
                </Avatar>
              )
            }
          />
          <Table.Column dataIndex="companyCode" title={t("company.code")} />
          <Table.Column dataIndex="name" title={t("company.name")} />
          <Table.Column
            dataIndex={["package", "name"]}
            title={t("company.package")}
          />
          <Table.Column
            dataIndex="activeStartTime"
            key="activeStartTime"
            title={t("company.active_time")}
            sorter
            render={(v) => v && <DateField value={v} format="DD/MM/YYYY" />}
          />
          <Table.Column
            dataIndex="activeEndTime"
            key="activeEndTime"
            sorter
            title={t("company.exp_time")}
            render={(v) =>
              v && (
                <DateField
                  style={{
                    color: dayjs(v).isBefore(dayjs()) ? "red" : "unset",
                  }}
                  value={v}
                  format="DD/MM/YYYY"
                />
              )
            }
          />
          <Table.Column
            key="createdTime"
            dataIndex={["createdTime"]}
            sorter
            title={t("company.rec_time")}
            render={(v) => <DateField value={v} format="DD/MM/YYYY" />}
          />

          <Table.Column
            width={120}
            title={t("common.action")}
            dataIndex="status"
            key="status"
            render={(_, record: BaseRecord) => (
              <Space onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  title={t("common.confirm_change_state")}
                  description={t("common.desc_change_state")}
                  onConfirm={() => {
                    mutate({
                      id: record.id ?? "",
                      resource: "company",
                      values: {
                        id: record.id ?? "",
                        status:
                          record.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
                      },
                    });
                  }}
                  okText={t("common.confirm_ok")}
                  cancelText={t("common.confirm_close")}
                >
                  <Switch checked={record.status == "ACTIVE"} />
                </Popconfirm>
                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </Card>
    </List>
  );
};

const FilterBlock = ({ filters, setFilters }: any) => {
  const t = useTranslate();
  const { formProps, form } = useForm({
    warnWhenUnsavedChanges: false,
  });

  useEffect(() => {
    form.setFieldsValue([]);
    filters.forEach((f: any) => {
      if (f.field == "status") {
        form.setFieldValue("status", f.value);
      }
      if (f.field == "activeEndTime") {
        form.setFieldValue("date", dayjs(f.value));
      }
    });
  }, [filters.length]);
  return (
    <Card>
      <Form
        {...formProps}
        onValuesChange={() => {
          const d = form.getFieldsValue() as any;
          setFilters(
            [
              {
                field: "status",
                operator: "eq",
                value: d.status != "ALL" ? d.status : undefined,
              },
              {
                field: "activeEndTime",
                operator: "eq",
                value: d.date ? dayjs(d.date).format("YYYY-MM-DD") : undefined,
              },
            ],
            "replace"
          );
        }}
      >
        <Row gutter={[12, 12]}>
          <Col span={8}>
          <Form.Item
              style={{ margin: 0 }}
              name="status"
              label={t("filter.status")}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={t("filter.status_placeholder")}
                options={[
                  { value: "ALL", label: t("common.all") },
                  { value: "ACTIVE", label: t("common.actived") },
                  { value: "INACTIVE", label: t("common.inactived") },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              style={{ margin: 0 }}
              name="date"
              label={t("company.exp_time")}
            >
              <DatePicker
                style={{ width: "100%" }}
                allowClear
                placeholder={t("company.select_date")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
