import { LockOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Show, useModalForm } from "@refinedev/antd";
import { Button, Card, Col, Form, Input, Modal, Row, Typography } from "antd";

import { useGetIdentity, useTranslate } from "@refinedev/core";
import AvatarCustom from "components/avatar";
import _ from "lodash";

export const Profile = () => {
  const { formProps, modalProps, show, form } = useModalForm({
    resource: "identity",
    action: "create",
    meta: {
      type: "update_password",
    },
    onMutationError(res) {
      const errors = _.get(res, "response.data.messages") || [];
      if (errors.length) {
        const fields = errors.map((e: any) => ({
          name: e.key,
          errors: [e.msg],
        }));
        form.setFields(fields);
        form.scrollToField(fields[0].name, {
          behavior: "smooth",
          block: "center",
        });
      }
    },
  });

  const t = useTranslate();

  const { data: user } = useGetIdentity<any>();
  return (
    <Show
      contentProps={{
        bodyStyle: { padding: 0, background: "none", boxShadow: "none" },
        style: { background: "none", boxShadow: "none" },
      }}
      title={t("profile.info")}
      headerButtons={
        <Button onClick={() => show()} icon={<LockOutlined />} type="primary">
          {t("profile.change_password")}
        </Button>
      }
    >
      {user && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card style={{ width: 500 }}>
            <div style={{ textAlign: "center", marginBottom: 12 }}>
              <AvatarCustom size={120} src={user.avatar}>
                { user?.role.name == "company_admin" ? user?.company?.companyCode: user?.fullName[0]}
              </AvatarCustom>
              <Typography.Title
                level={3}
                style={{ marginBottom: 6, marginTop: 6 }}
              >
                {user?.fullName || user?.email}
              </Typography.Title>
              <Typography.Text style={{ opacity: 0.6 }}>
                {user?.role?.name == "company_manager" &&
                  t("common.role.company_manager")}
                {user?.role?.name == "company_admin" &&
                  t("common.role.company_admin")}
                {user?.role?.name == "supper_admin" &&
                  t("common.role.supper_admin")}
              </Typography.Text>
            </div>

            <Row gutter={[24, 24]}>
              <Col span={12} style={{ textAlign: "right" }}>
                <MailOutlined /> {user?.email}
              </Col>
              <Col span={12}>
                <PhoneOutlined /> {user?.phone}
              </Col>
            </Row>
          </Card>
        </div>
      )}
      <Modal
        {...modalProps}
        title={t("user.change_password")}
        width={400}
        okText={t("passsword.save")}
        cancelText={t("passsword.close")}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item
            label={t("password.old_password")}
            name={["passwordOld"]}
            rules={[
              {
                required: true,
                message: t("common.required"),
              },
            ]}
          >
            <Input.Password
              maxLength={200}
              placeholder={t("password.old_password")}
              autoComplete="one-time-code"
            />
          </Form.Item>

          <Form.Item
            label={t("user.password")}
            name={["password"]}
            rules={[
              {
                required: true,
                message: t("common.required"),
              },
              {
                validator(rule, value, callback) {
                  if (!value) Promise.resolve();
                  if (value?.length < 6) {
                    return Promise.reject(t("common.password_620"));
                  }
                  if (!/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value)) {
                    return Promise.reject(t("common.password_character"));
                  }
                  if (
                    value?.indexOf(" ") > -1 ||
                    !/^[^\s\u00C0-\u024F]+$/.test(value)
                  ) {
                    return Promise.reject(t("common.password_space"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password
              maxLength={200}
              autoComplete="one-time-code"
              placeholder={t("user.password")}
            />
          </Form.Item>
          <Form.Item
            dependencies={["password"]}
            label={t("user.password_confirm")}
            name={["password_confirm"]}
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (getFieldValue("password") != value) {
                    return Promise.reject(t("common.password_same"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              autoComplete="one-time-code"
              placeholder={t("user.password_confirm")}
              maxLength={200}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Show>
  );
};
